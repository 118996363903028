class VacationRequestNew {
  $scope:                 any;
  $routeParams:           any;
  $location:              any;
  $filter:                any;
  $timeout:               any;
  dbFactory:              any;
  api:                    any;
  loaderFactory:          any;
  notification:           any;
  errFactory:             any;
  assignmentFactory:      any;
  vacationRequestFactory: any;
  vacationRequestService: any;
  holidaysService:        any;

  id:         number = null;
  leaveType:  any    = null;
  leaveTypes: any[]  = [
   { label: 'annual',   value: 'annual'   },
   { label: 'special',  value: 'special'  },
   { label: 'overtime', value: 'overtime' },
  ];

  reason:      any   = null;
  reasonTypes: any[] = [
    { label: 'wedding_birth',            value: 'wedding_birth'            },
    { label: 'death_of_close_relatives', value: 'death_of_close_relatives' },
    { label: 'death_of_relatives',       value: 'death_of_relatives'       }
  ];

  from:               string;
  to:                 string;

  assignmentsList:    any[];
  assignments:        any[]  = null;
  assignment:         any    = null;

  holidaysList:       any[];
  holidays:           any[]  = null;

  releaseOption:      any    = null;
  signature:          string = null;
  signer:             string = null;

  availableDays:      number;
  validUntil:         Date;

  minDate:            Date;
  min:                string;
  statusList:         any;
  statusListFiltered: any
  constructor($routeParams: any, $scope: any, $location: any, $filter: any, $timeout: any, AuthService: any, API: any, DBFactory: any, LoaderFactory: any, Notification: any, ErrFactory: any, AssignmentFactory: any, VacationRequestFactory: any, VacationRequestService: any, HolidaysService: any) {
    Object.defineProperties(this, {
      $routeParams:           { value: $routeParams           },
      $scope:                 { value: $scope                 },
      $location:              { value: $location              },
      $filter:                { value: $filter                },
      $timeout:               { value: $timeout               },
      api:                    { value: API                    },
      dbFactory:              { value: DBFactory              },
      loaderFactory:          { value: LoaderFactory          },
      notification:           { value: Notification           },
      errFactory:             { value: ErrFactory             },
      assignmentFactory:      { value: AssignmentFactory      },
      vacationRequestFactory: { value: VacationRequestFactory },
      vacationRequestService: { value: VacationRequestService },
      holidaysService:        { value: HolidaysService        },
    });
    this.id         = this.$routeParams.id;
    this.leaveType  = { label: 'annual', value: 'annual' };

    this.minDate    = new Date(new Date().getTime() - 4*7*24*60*60*1000);
    this.min        = $filter('date')(this.minDate, 'yyyy-MM-dd');

    this.statusList = [
      { status: 1, label: 'timeTrackings.releaseNow'               },
      { status: 3, label: 'timeTrackings.releaseNeedClarification' }
    ];

    let user           = AuthService.authorizedUser;
    this.availableDays = user?.vacation_info?.available_days;
    this.validUntil    = user?.vacation_info?.valid_until ? $filter('date')(new Date(user.vacation_info.valid_until), 'dd.MM.yyyy') : null;

    this.loadAssignments();
    this.loadHolidays();
    this.prepareInputs();
  }

  private prepareInputs(): void {
    this.prepareInput('from');
    this.prepareInput('to');
  }

  private prepareInput(field: string): void {
    let input = document.getElementById(`vr-${field}`) as HTMLInputElement;

    if (this.id) input.type = 'date';
    else {
      input.addEventListener('focus', () => {
        input.type = 'date';
        if (!input.value) {
          let value   = this.toString(new Date());
          input.value = value
          this[field] = value;
          this.dateChanged(field);

          setTimeout(() => {
            if (window.cordova) {
              if (deviceIsAndroid) input.click();
              if (deviceIsIOS)     input.focus();
            } else (input as any).showPicker();
          });
        }
      });

      input.addEventListener('blur',  () => setTimeout(() => { input.type = input.value ? 'date' : 'text' }));
      input.addEventListener('input', () => setTimeout(() => { this.updateInput(`vr-${field}`)            }));
    }

  }

  private loadAssignments(): Promise<any> {
    return this.assignmentFactory.getBetaAssignments('vacation_requests')
    .then(assignments => {
      this.assignmentsList = assignments.filter(assignment => assignment.ends_at > this.minDate)
                                        .filter(assignment => assignment.active);
    })
    .catch(err => console.error(err))
    .then(() => this.loadAndPrefillForm());
  }

  private loadHolidays(): Promise<any> {
    return this.holidaysService.loadHolidays(null, this.minDate, new Date())
    .then(res => this.holidaysList = res);
  }

  private loadAndPrefillForm(): void {
    if (this.id) {
      this.vacationRequestService.getVacationRequestById(+this.id)
      .then(vr => new this.vacationRequestFactory.VacationRequestSubmitted(vr))
      .then(vr => {
        let type = this.leaveTypes.find(t => t.value === vr.leaveType);

        if (type && vr.startsOn && vr.endsOn) {
          this.$timeout(() => {
            this.leaveType = type;
            this.from = this.toString(vr.startsOn);
            this.to   = this.toString(vr.endsOn);

            this.updateValue('vr-from', this.from);
            this.updateValue('vr-to',   this.to  );

            let reason = this.reasonTypes.find(t => t.value === vr.reason);
            if (reason) this.reason = reason;
            this.mapAssignments();
            this.mapHolidays();
            this.resetReleaseOptions();
            this.resetSignature();
          });
        }
      });
    }
  }

  typeChanged(option): void {
    this.leaveType = option;
    this.reason = null;
    this.resetSignature();
  }

  reasonChanged(option): void {
    this.reason = option;
    this.resetSignature();
    this.dateChanged('to');
  }

  dateChanged(dateField: string = null) {
    if (this[dateField]) {
      this.validationCheck();
      this.preselectDates(dateField);
    }
    this.mapAssignments();
    this.mapHolidays();
    this.resetReleaseOptions();
    this.resetSignature();
  }

  validationCheck(): void {
    let min = new Date(this.min);
    min.setHours(0,0,0,0);
    if (this.from) this.from = this.toString(new Date(Math.max(new Date(this.from).getTime(), min.getTime())));
    if (this.to)   this.to   = this.toString(new Date(Math.max(new Date(this.to).getTime(), min.getTime())));

    if (!this.to && this.from) {
      this.to = this.toString(new Date(this.from));
      this.updateInput('vr-to');
    }

    if (!this.from && this.to) {
      this.from = this.toString(new Date(this.to));
      this.updateInput('vr-from');
    }
  }

  preselectDates(dateField: string): void {
    if (this.from && this.to) {
      if (dateField === 'from' && new Date(this.to).getTime() < new Date(this.from).getTime()) this.to   = this.toString(new Date(this.from));
      if (dateField === 'to'   && new Date(this.to).getTime() < new Date(this.from).getTime()) this.from = this.toString(new Date(this.to));
      if (this.reason?.value === 'wedding_birth' || this.reason?.value === 'death_of_relatives' ) {
        this.from = this.toString(new Date(this[dateField]));
        this.to   = this.toString(new Date(this[dateField]));
      }
      if (this.reason?.value === 'death_of_close_relatives') {
        if (dateField === 'from' && this.workingVacationDays() > 2) this.to   = this.toString(new Date(this.from).getDay() === 5 ? new Date(new Date(this.from).getTime()+3*24*60*60*1000) : new Date(new Date(this.from).getTime()+1*24*60*60*1000));
        if (dateField === 'to'   && this.workingVacationDays() > 2) this.from = this.toString(new Date(new Date(this.to).getTime() - (new Date(this.to).getDay() ? new Date(this.to).getDay() === 1 ? 3 : 1 : 2 )*24*60*60*1000));
      }
    }
  }

  mapAssignments(): void {
    if (this.from && this.to) {
      this.assignments = this.assignmentsList.filter(a => 
        a.starts_at.getTime() <= new Date(this.from).getTime() && a.ends_at.getTime() >= new Date(this.from).getTime() ||
        a.starts_at.getTime() >= new Date(this.from).getTime() && a.ends_at.getTime() <= new Date(this.to).getTime()   ||
        a.starts_at.getTime() <= new Date(this.to).getTime()   && a.ends_at.getTime() >= new Date(this.to).getTime()
      );
      this.assignment  = this.assignments.length ? this.assignments[0] : null;
    } else {
      this.assignments = null;
      this.assignment  = null;
    }
  }

  mapHolidays(): void {
    if (this.from && this.to) this.holidays = this.holidaysList.filter(h => new Date(this.from).getTime() >= h.date.getTime() && new Date(this.to).getTime() <= h.date.getTime());
    else this.holidays = null;
  }

  changeAssignment(): void {
    this.releaseOption = null;
    this.resetReleaseOptions();
    this.resetSignature();
  }

  resetReleaseOptions(): void {
    this.statusListFiltered = [...this.statusList];
    if (this.assignment?.can_release_later) this.statusListFiltered.splice(1, 0, { status: 2, label: 'timeTrackings.releaseLater' }); 
  }

  resetSignature(): void {
    this.signature = null;
    this.signer    = null;
  }

  reset(): void {
    this.leaveType     = { label: 'annual', value: 'annual' };
    this.reason        = null
    this.from          = null
    this.to            = null
    this.assignments   = null;
    this.assignment    = null;
    this.releaseOption = null;
    this.resetSignature();
  }

  workingVacationDays(): number {
    let count = 0;
    for (let start = new Date(this.from); start.getTime() <= new Date(this.to).getTime(); start.setDate(start.getDate()+1)) { 
      if (start.getDay() > 0 && start.getDay() < 6) count++;
    }
    return count;
  }

  totalVacationDays(): number {
    if (this.from && this.to) return Math.round((+this.to - +this.from) / (24*60*60*1000))+1;
    else return 0;
  }

  submit(): Promise<any> {
    this.loaderFactory.show();
    let data = { vacation_request: {
      leave_type:                this.leaveType.value,
      starts_on:                 this.from,
      ends_on:                   this.to,

      assignment_id:             this.assignment                  ? this.assignment.id : null,
      reason:                    this.reason                      ? this.reason.value  : null,

      signer:                    this.signer                      ? this.signer        : null,
      base64_customer_signature: this.signature                   ? this.signature     : null,
      customer_rejection:        this.releaseOption?.status === 3 ? true               : null
    }};

    return Promise.resolve()
    .then(() => this.api.submitVacationRequest(data))
    .then(() => {
      this.loaderFactory.hide();
      return this.notification.alert({
        title: 'vacationRequest.successfulTitle',
        desc:  'vacationRequest.vacationRequestBeingChecked'
      }, () => this.$location.path('/'));
    })
    .catch(err => {
      if (err instanceof this.errFactory) err.notify();
      else console.log(err);
    })
    .then(() => this.loaderFactory.hide());
  }

  update(review: string): Promise<any> {
    let data = { vacation_request: { external_review: review }};

    return Promise.resolve()
    .then(() => this.api.updateVacationRequest(this.id, data))
    .then(() => {
      this.loaderFactory.hide();
      return this.notification.alert({
        title: 'vacationRequest.successfulTitle',
        desc:  'vacationRequest.successfulMessage'
      }, () => this.$location.path('/'));
    })
    .catch(err => {
      if (err instanceof this.errFactory) err.notify();
      else console.log(err);
    })
    .then(() => this.loaderFactory.hide());
  }

  readyToSign(): boolean {
    if (this.releaseOption?.status === 1) {
      if (this.leaveType.value === 'special' && !this.reason) return false;
      return true;
    }
    return false;
  }

  maxDaysLimit(): boolean {
    if (this.reason?.value === 'wedding_birth'            && this.workingVacationDays() > 1) return false;
    if (this.reason?.value === 'death_of_close_relatives' && this.workingVacationDays() > 2) return false;
    if (this.reason?.value === 'death_of_relatives'       && this.workingVacationDays() > 1) return false;
  }

  readyToSubmit(): boolean {
    if (!this.leaveType || !this.from || !this.to)                       return false;
    if (this.leaveType.value === 'special' && !this.reason)              return false;
    if (this.maxDaysLimit())                                             return false;
    if (!this.id && this.assignment && !this.releaseOption)              return false;
    if (!this.id && this.releaseOption?.status === 1 && !this.signature) return false;
    return true;
  }

  fillAllFieldsNote(): void {
    this.notification.alert({
      title: 'note',
      desc:  'errors.EnterAllFields'
    });
  }

  private toString(date): string {
    return date instanceof Date ? this.$filter('date')(date, 'yyyy-MM-dd') : date;
  }

  private updateInput(inputName): void {
    let input = document.getElementById(inputName);
    input.focus();
    setTimeout(() => input.blur());
  }

  private updateValue(inputName: string, date): void {
    let input   = document.getElementById(inputName) as HTMLInputElement;
    input.value = this.toString(date);
  }

}

window.app.component('vrNew', {
  template: require('scripts/components/vacation-request/vr-new/vr-new.html'),
  controller: ['$routeParams', '$scope', '$location', '$filter', '$timeout', 'AuthService', 'API', 'DBFactory', 'LoaderFactory', 'Notification', 'ErrFactory', 'AssignmentFactory', 'VacationRequestFactory', 'VacationRequestService', 'HolidaysService', VacationRequestNew]
});
