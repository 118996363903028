class VacationRequestListItem {
  notification: any;

  vacationRequest: any;
  unread:          any;
  constructor (Notification) {
    Object.defineProperties(this, {
      notification: { value: Notification }
    });
  }
  
  showHoliday(): void {
    if (this.vacationRequest.holidays?.length) this.notification.message(this.vacationRequest.holidays.map(h => h.name).join('\n'), 'short', 'top');
  }

}

window.app.component('vacationRequestListItem', {
  template: require('scripts/components/vacation-request/vr-list/vr-list-item/vr-list-item.html'),
  controller: ['Notification', VacationRequestListItem],
  bindings: {
    vacationRequest: '<',
    unread:          '=',
  }
});

