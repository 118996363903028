class TutorialsList {
  constructor($location, API) {
    Object.defineProperties(this, {
      $location: { value: $location },
      api: { value: API }
    });
    // this.fetchList();

    this.tutorials = [
      {
        name: "tutorial.tutorialNames.createActivityReport",
        path: "tracking"
      }, {
        name: "tutorial.tutorialNames.photoDocument",
        path: "photo-document"
      }, {
        name: "tutorial.tutorialNames.manualActivityReport",
        path: "manual-tracking"
      }
    ];
  }

  fetchList() {
    return this.api.getTutorials()
    .then((data) => {
      this.tutorials = data;
    })
  }

  openTutorial(tut) {
    this.$location.path(`/tutorial/${tut}`);
  }

}

app.component('tutorialsList', {
  template: require('scripts/components/tutorials/tutorials-list/tutorials-list.html'),
  controller: TutorialsList
});
