class VacationRequestList {
  $scope:          any;
  $location:       any;
  $translate:      any;
  api:             any;
  appFactory:      any;
  databaseService: any;
  loaderFactory:   any;
  notification:    any;
  pnListService:   any;
  vacationRequestFactory: any;
  vacationRequestService: any;

  columns:    any[]   = [{ label: 'timeTrackings.operation', sort: 'assignment_title' }, { label: 'activityReport.period', sort: 'starts_on' }];
  column:     string  = 'starts_on';
  state:      string  = null;
  activeTab:  string  = null;
  reverse:    boolean = false;
  page:       number  = 1;
  totalCount: number  = 0;

  unreadVacations:       any[]  = [];
  vacationRequests:      any[]  = [];
  localVacationRequests: any[]  = [];
  COLECTION_NAME:        string = 'vacation_requests';
  constructor($scope: any, $location: any, $translate: any, API: any, AppFactory: any, DatabaseService: any, LoaderFactory: any, Notification: any, PNListService: any, VacationRequestFactory: any, VacationRequestService: any) {
    Object.defineProperties(this, {
      $scope:                 { value: $scope                 },
      $location:              { value: $location              },
      $translate:             { value: $translate             },
      api:                    { value: API                    },
      appFactory:             { value: AppFactory             },
      databaseService:        { value: DatabaseService        },
      loaderFactory:          { value: LoaderFactory          },
      notification:           { value: Notification           },
      pnListService:          { value: PNListService          },
      vacationRequestFactory: { value: VacationRequestFactory },
      vacationRequestService: { value: VacationRequestService }
    });

    Promise.all([
      this.loadVR(),
      this.loadUnreadVacations()
    ]);
  }

  private loadUnreadVacations(): Promise<any> {
    return this.pnListService.getOwn(['vacation_request_approved', 'vacation_request_rejected']).then(pn => this.unreadVacations = pn || []);
  }

  isUnread(vr) {
    return this.unreadVacations.find(uv => uv.trigger?.source_id === vr.id);
  }

  changeTab(state: string): void {
    if (this.state === state) this.state = null;
    else this.state = state;
    this.page = 1;
    this.changeTitle(this.state);
    this.loadVR();
  }

  private changeTitle(state: string): void {
    let title;
    switch (state) {
      case 'approved':
        title = "vacationRequest.overviewVacationApproved";
        break;
      case 'awaiting':
        title = "vacationRequest.overviewVacationAwaiting";
        break;
      case 'rejected':
        title = "vacationRequest.overviewVacationRejected";
        break;
      default:
        title = "vacationRequest.overviewVacation";
        break;
    }
    this.$translate([title]).then(t => this.appFactory.setTitle(t[title]));
  }

  sortCallback(column: string, reverse: boolean): void {
    this.column  = column;
    this.reverse = reverse;
    this.loadVR();
  }

  paginationCallback(page: number): void {
    this.page = page;
    this.loadVR();
  }

  private loadVR(): Promise<any> {
    return Promise.resolve()
    .then(() => {
      if (this.appFactory.isNetwork()) return this.refreshVR();
      else return this.loadLocalVR();
    });
  }

  private refreshVR(page = null): Promise<any> {
    this.loaderFactory.show();
    if (!page) page = this.page;
    return this.vacationRequestService.getVacationRequestsOverview(page, this.column, this.reverse, this.state)
    .then(res => {
      this.page = page;
      if (res) {
        this.totalCount = res.meta.paging.total_pages;
        this.vacationRequests = res.vacation_requests.map(r => new this.vacationRequestFactory.VacationRequestSubmitted(r));
      }
      this.$scope.$apply();
      return Promise.all(this.vacationRequests.map((data) => data.save()));
    })
    .catch((err) => console.error(err))
    .then(() => this.loaderFactory.hide())
    .then(() => Promise.all(this.unreadVacations
      .filter(uv => this.vacationRequests.find(vr => uv.trigger?.source_id === vr.id))
      .map(uv => this.pnListService.markAsRead(uv.id))
    ));
  }

  private loadLocalVR(): void {
    this.loaderFactory.show();
    let extraFilter;
    if (this.state) extraFilter= {
      field: 'state',
      trueOptions:  this.state === 'approved' ? ['approved_by_internal'] : this.state === 'rejected' ? ['rejected_by_internal'] : '',
      falseOptions: this.state === 'awaiting' ? ['approved_by_internal', 'rejected_by_internal'] : ''
    };
    this.databaseService.getLocalPagination(this.COLECTION_NAME, this.page, this.column, this.reverse, extraFilter)
    .then(res => {
      if (res) {
        this.page       = res.page;
        this.totalCount = res.totalCount;

        return Promise.all(res.sortedData.map(item => new this.vacationRequestFactory.VacationRequestSubmitted(item)));
      } else this.$location.path('/projects');
    })
    .then(res => {
      this.vacationRequests = res;
      this.$scope.$apply();
    })
    .catch(err => console.error(err))
    .then(() => this.loaderFactory.hide());
  }


}

window.app.component('vrList', {
  template: require('scripts/components/vacation-request/vr-list/vr-list.html'),
  controller: ['$scope', '$location', '$translate', 'API', 'AppFactory', 'DatabaseService', 'LoaderFactory', 'Notification', 'PNListService', 'VacationRequestFactory', 'VacationRequestService', VacationRequestList]
});
